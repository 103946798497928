
import React from "react"
import { Route, Routes, } from "react-router-dom"
import Contact from "./pages/Contact/Contact";
import Home from './pages/Home/Home';
import Works from "./pages/Works/Works";
import styled from "styled-components";

export const RoutesHelper = {
    main: "/",
    contact: "/contact",
    works: "/works",
    project: "project/1",
    error: "404",
}

const Main = () => {
    return <>
        <Routes>
            <Route path={RoutesHelper.main} element={<Home />} />
        </Routes>
    </>
}

export default Main
