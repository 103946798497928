import React, { useEffect, useState } from "react";
import { CardStyle } from "./CardStyles";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import project1 from "../../assets/images/works/1.png";
import project2 from "../../assets/images/works/2.png";
import project3 from "../../assets/images/works/3.png";
import project4 from "../../assets/images/works/4.png";
import project5 from "../../assets/images/works/5.png";
import project6 from "../../assets/images/works/6.mp4";
import project7 from "../../assets/images/works/7.png";
import project8 from "../../assets/images/works/8.mp4";
import project9 from "../../assets/images/works/9.png";
import project10 from "../../assets/images/works/10.png";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Content } from "./Content";
import { Link } from "react-router-dom";

// export const Background = require('./bg/background.jpg');
// export const First = require('./photos/first.jpg');
// export const Second = require('./photos/second.jpg');
// export const LinkedIn = require('./social/linkedin.png');

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  content: JSX.Element;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.black50};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: absolute;
  bottom: 0;
  padding: 40px;
  width: calc(100vw - 40%);
  height: calc(100vh - 150px);
  border-radius: 25px 25px 0px 0px;
  background-color: ${colors.black50};
  backdrop-filter: saturate(0) blur(10px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${colors.white50};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.red};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.red50};
  }
`;

const CloseButton = styled.span`
  position: absolute;
  top: 12px;
  width: 64px;
  height: 32px;
  background-color: ${colors.red};
  border-radius: 32px;
  color: #fff;
  font-size: 24px;
  line-height: 26px;
`;

const Modal: React.FC<ModalProps> = ({ isOpen, closeModal, content }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay>
      <CloseButton onClick={closeModal}>&times;</CloseButton>
      <ModalContent>{content}</ModalContent>
    </ModalOverlay>
  );
};

const Card = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element>();

  const openModal = (content: JSX.Element) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <CardStyle.Cont>
      {/* Add more buttons for other modals */}

      <Modal
        isOpen={modalOpen}
        closeModal={closeModal}
        content={modalContent!}
      />
      <CardStyle.MasonryContainer>
        <ParallaxProvider>
          <Parallax speed={-4}>
            <CardStyle.MasonryItem>
              <Link
                to="https://dribbble.com/shots/20752336-Smiley-Ballons"
                target="_blank"
              >
                <img src={project1} className="project" />
              </Link>
            </CardStyle.MasonryItem>

            <CardStyle.MasonryItem onClick={() => openModal(Content.yovi)}>
              <img src={project2} className="project" />
            </CardStyle.MasonryItem>

            <CardStyle.MasonryItem>
              <Link
                to="https://twitter.com/fede_maldo/status/1631743790229717017"
                target="_blank"
              >
                <img src={project5} className="project" />
              </Link>
            </CardStyle.MasonryItem>
          </Parallax>
          <Parallax speed={10}>
            <CardStyle.MasonryItem onClick={() => openModal(Content.crafty)}>
              <img src={project3} className="project" />
            </CardStyle.MasonryItem>

            <CardStyle.MasonryItem>
              <video autoPlay muted loop controls={false}>
                <source src={project6} type="video/mp4" className="project" />
                Your browser does not support the video tag.
              </video>
            </CardStyle.MasonryItem>
          </Parallax>
          <Parallax speed={-8}>
            <CardStyle.MasonryItem>
              <video autoPlay muted loop controls={false}>
                <source src={project8} type="video/mp4" className="project" />
                Your browser does not support the video tag.
              </video>
            </CardStyle.MasonryItem>

            <CardStyle.MasonryItem onClick={() => openModal(Content.burguers)}>
              <img src={project7} className="project" />
            </CardStyle.MasonryItem>

            <CardStyle.MasonryItem>
              <Link
                to="https://dribbble.com/shots/22046401-A-Literal"
                target="_blank"
              >
                <img src={project10} className="project" />
              </Link>
            </CardStyle.MasonryItem>
          </Parallax>
        </ParallaxProvider>
      </CardStyle.MasonryContainer>
    </CardStyle.Cont>
  );
};

export default Card;
