import React from 'react';
import { Link } from 'react-router-dom';
import { RoutesHelper } from '../../Main';
import styled from 'styled-components';


const ContactInfoCont = styled.div`
display: flex;
flex-direction: column;
`

const Info = styled.p`
    font-size: 32px;
    color: #fff;

    @media only screen and (max-width: 768px) {
    font-size: 24px;

}


@media only screen and (max-width: 425px) {
    font-size: 12px;

}
`


const Header = () => {
    return (
        <ContactInfoCont>
            <Info>
                iamfedericomaldonado@gmail.com
            </Info>
        </ContactInfoCont>
    )
}

export default Header;
