import styled, { createGlobalStyle } from "styled-components";
import { colors } from "../../styles/colors";
import { HeaderStyle } from "../Header/HeaderStyle";

interface CursorStyleProps {
    x: number;
    y: number;
    isHovered: boolean;
    content: string;
}

const GlobalStyle = createGlobalStyle`
  a {
    cursor: none !important;
  }
`;

const Cursor = styled.div<CursorStyleProps>`
  position: fixed;
  
  z-index: 10000 !important;
  width: 12px;
  height: 12px;
  background-color: ${colors.red};
  opacity: 0.8;
  mix-blend-mode: difference;
  pointer-events: none;
  border-radius: 24px;
  transform: translate(-50%, -50%);
  transition: transform 0.2s , width 0.3s;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;

  font-family: "Noto Emoji";
  color: ${colors.white};
  font-size: 10px;
  line-height: 24px;

  ${(props) =>

        props.isHovered &&
        `
        :before {
            content: '${props.content}';
        }
            // width: 48px;
            transform: translate(-50%, -50%) scale(3);
  `

  }
`;

export const CursorStyle = {
    Cursor,
    GlobalStyle,
};
