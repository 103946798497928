import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";
import { ReactNode } from "react";


interface AnimatedCharacterProps {
  index: number;
  children: ReactNode;
}

const HomeIn = keyframes`
  from {opacity: 0;}
  25%, to {opacity: 1;}
`;


const FooterIn = keyframes`
  from {margin-bottom: -200px; opacity: 0;}
  25%, to {margin-top:0; opacity: 1;}
`;


const slideIn = keyframes`
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AnimatedCharacter = styled.span<AnimatedCharacterProps>`
  display: inline-block;
  overflow: hidden;
  line-height: 1em;
  animation: ${slideIn} 1s ${props => props.index * 0.05}s both;
`;

const All = styled.div`
    animation-name: ${HomeIn};
    animation-fill-mode: both;
    animation-duration: 3s;
    animation-delay: 0.5;
`

const ContTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  color: #fff;
  font-size: 96px;
  font-weight: black;

  
  @media only screen and (max-width: 768px) {
    font-size: 64px;
}

@media only screen and (max-width: 425px) {
    font-size: 42px;

}
`;

const ReelButton = styled.a`
  align-self: center;
  width: 150px;
  height: 40px;
  color: ${colors.red};
  background-color: ${colors.transparent};
  border-radius: 40px;
  border: solid 2px ${colors.red};
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 30px;
  color: #fff;
  height: auto;
  width: calc(100% - 60px);
  display: flex;
  align-content: space-around;
  justify-content: space-between;

  
  animation-name: ${FooterIn};
    animation-fill-mode: both;
    animation-duration: 1s;
    animation-delay: 0.5s;

  p {
    margin: 0;
    font-size: 20px;

     
  @media only screen and (max-width: 768px) {
    font-size: 15px;
}

@media only screen and (max-width: 425px) {
    font-size: 12px;

}
  }
`;

export const HomeStyles = {
  All,
  Footer,
  ContTitle,
  Title,
  ReelButton,
  AnimatedCharacter,
};
