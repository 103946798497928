import React from "react";
import styled, { keyframes } from "styled-components";
import craftyIcon from "../../assets/images/icons/crafty.png";
import craftyA from "../../assets/images/works/3a.png";
import craftyB from "../../assets/images/works/3.png";

import yoviIcon from "../../assets/images/icons/yovi.png";
import yoviA from "../../assets/images/works/2a.png";
import burguersIcon from "../../assets/images/icons/burguers.png";
import burguersA from "../../assets/images/works/7a.png";
import burguersB from "../../assets/images/works/7b.png";

import { colors } from "../../styles/colors";
import { ContentStyle } from "./ContentStyles";

const happyBallons = <h1>Happy Ballons</h1>;
const yovi = (
  <>
    <ContentStyle.Cont>
      <ContentStyle.ContTitle>
        <ContentStyle.ContText>
          <ContentStyle.Title>YoVi</ContentStyle.Title>
          <ContentStyle.Sub>
            website <ContentStyle.Emoji>🌐</ContentStyle.Emoji>
          </ContentStyle.Sub>
        </ContentStyle.ContText>

        <ContentStyle.Sticker src={yoviIcon} factor={0.1} />
      </ContentStyle.ContTitle>
      <ContentStyle.Desc>
        It is a virtual platform designed for university students. The main idea
        was to be able to share study materials such as PDFs, presentations, and
        other materials created by both students and teachers. Currently, a new
        version is being developed and it will be launched later this year.
      </ContentStyle.Desc>
      <ContentStyle.DescImages>
        <ContentStyle.Image
          src={yoviA}
          background={true}
          width={405}
          height={510}
        ></ContentStyle.Image>
      </ContentStyle.DescImages>

      <ContentStyle.Desc>
       first version was released in 2020
      </ContentStyle.Desc>
    </ContentStyle.Cont>
  </>
);
const can = <h1>can</h1>;
const burguers = (
  <>
    <ContentStyle.Cont>
      <ContentStyle.ContTitle>
        <ContentStyle.ContText>
          <ContentStyle.Title>Simpler Burguers</ContentStyle.Title>
          <ContentStyle.Sub>
            case of study <ContentStyle.Emoji>✏️</ContentStyle.Emoji>
          </ContentStyle.Sub>
        </ContentStyle.ContText>

        <ContentStyle.Sticker src={burguersIcon} factor={0.1} />
      </ContentStyle.ContTitle>
      <ContentStyle.Desc>
        In this Medium article, I want to share with you the UI/UX concept study
        I conducted for the brand "SimplerBurgers." My goal was to create a new,
        simple, and attractive experience for SimplerBurgers users through a
        minimalist yet functional application. To achieve this, I planned the
        development stages in a sprint and assigned myself tickets. I conducted
        thorough research for both branding and app interface, drawing
        inspiration from the "one line drawing" movement for the brand's visual
        style.
      </ContentStyle.Desc>
      <ContentStyle.DescImages>
        <ContentStyle.Image
          src={burguersA}
          background={true}
          height={150}
        ></ContentStyle.Image>
      </ContentStyle.DescImages>
      <ContentStyle.Link
        href="https://medium.com/@fede-maldo/simpler-burgers-estudio-de-ux-ui-51aa06648da2"
        target="_blank"
      >
        Read the Medium <ContentStyle.Emoji>👁️</ContentStyle.Emoji>{" "}
      </ContentStyle.Link>
    </ContentStyle.Cont>
  </>
);
const pancho = <h1>pancho</h1>;

const crafty = (
  <>
    <ContentStyle.Cont>
      <ContentStyle.ContTitle>
        <ContentStyle.ContText>
          <ContentStyle.Title>Crafty Amigo</ContentStyle.Title>
          <ContentStyle.Sub>
            digital product <ContentStyle.Emoji>🌐</ContentStyle.Emoji>{" "}
          </ContentStyle.Sub>
          <ContentStyle.Sub>
            three js <ContentStyle.Emoji>📚</ContentStyle.Emoji>{" "}
          </ContentStyle.Sub>
        </ContentStyle.ContText>

        <ContentStyle.Sticker src={craftyIcon} factor={0.1} />
      </ContentStyle.ContTitle>
      <ContentStyle.Desc>
        The main idea was to be able to create structures using PVC pipes, but
        the 3d engine was intended to have great adaptability for all types of
        structures, such as wooden and metal pieces, among others.
      </ContentStyle.Desc>
      <ContentStyle.DescImages>
        <ContentStyle.Image
          src={craftyA}
          width={440}
          height={350}
        ></ContentStyle.Image>
        <ContentStyle.Image
          src={craftyB}
          width={220}
          height={220}
        ></ContentStyle.Image>
      </ContentStyle.DescImages>

      <ContentStyle.Desc>
        The main idea was to be able to create structures using PVC pipes, but
        the 3d engine was intended to have great adaptability for all types of
        structures, such as wooden and metal pieces, among others.
      </ContentStyle.Desc>
      <ContentStyle.Link href="https://make.craftyamigo.com/" target="_blank">
        Try it <ContentStyle.Emoji>👁️</ContentStyle.Emoji>{" "}
      </ContentStyle.Link>
    </ContentStyle.Cont>
  </>
);

export const Content = {
  happyBallons,
  yovi,
  crafty,
  can,
  burguers,
  pancho,
};
