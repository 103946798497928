import styled from "styled-components";
import { colors } from "../../styles/colors";

const Cont = styled.div`
  margin-top: 100px;
  width: 70%;
`;

const MasonryContainer = styled.div`
  column-count: 3;
  column-gap: 20px;
`;

const MasonryItem = styled.div`
  padding: 10px;
  width: 100%;
    border-radius: 8px;
    
  /* &:hover::before {
    z-index: 100;
        position: absolute;

        width: 32px;
        height: 32px;

        content: "+";
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
        border-radius: 8px;
        background-color: ${colors.red};
    } */

  img {
    display: block;
    width: 100%;
    border-radius: 4px;
  }

  video {
    display: block;
    width: 100%;
    border-radius: 4px;
  }
`;

export const CardStyle = {
  Cont,
  MasonryContainer,
  MasonryItem,
};
