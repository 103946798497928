import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";
import { CursorStyle } from "../Cursor/CursorStyle";

interface UlProps {
  align: "start" | "end";
}

const LiLeft = keyframes`
  from {margin-left: -500px;}
  50%, to {margin-left:0;}
`;

const LiRight = keyframes`
  from {margin-right: -500px;}
  50%,to {margin-right:0;}
`;

const Header = styled.header`
z-index: 1000;
  display: flex;
  align-content: space-around;
  justify-content: space-between;
  width: calc(100% - 60px);
  mix-blend-mode: difference;
  position: fixed;
    left: 50%;
    transform: translate(-50%, 0);

    
`;

const Nav = styled.nav`
  margin: 0;
`;

const Ul = styled.ul<UlProps>`
  padding: 0;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  color: ${colors.black};
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  a:visited,
  a:link {
    text-decoration: none;
    color: ${colors.white};
  }
  li:hover:active {
    color: ${colors.white};
    background-color: ${colors.red};
  }
  align-items: ${({ align }) =>
    align === "start" ? "flex-start" : "flex-end"};

    li{
  animation-name: ${({ align }) =>
    align === "start" ? LiLeft : LiRight};
    animation-fill-mode: both;
    animation-duration: 2s;
    animation-delay: 5;
  }
`;

const Li = styled.li`
  height: 24px;
  line-height: 24px;
  transition: color 0.2s, outline 0.2s, background-color 0.2s;
  color: ${colors.red};
  list-style-type: none;

  :hover {
    border-color: ${colors.red};
    text-decoration-line: line-through;
  }

`;

export const HeaderStyle = {
  Header,
  Nav,
  Ul,
  Li,
};
