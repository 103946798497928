import React, { ReactNode, useEffect, useState } from "react";
import ScreenCursor from "../../components/Cursor/Cursor";
import Header from "../../components/Header/Header";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import ContactInfo from "../../components/ContactInfo/ContactInfo";
import { HomeStyles } from "./HomeStyles";
import AboutMe from "../../components/AboutMe/AboutMe";
import LoaderScreen from "../../components/Loader/Loader";
import MeParallax from "../../components/Me/Me";
import Card from "../../components/Card/Card";


interface TitleProps {
   index: number;
  children: ReactNode;
}

const Home = () => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaderExiting, setIsLoaderExiting] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
      setIsLoaderExiting(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }, 3000);
  }, []);
  
  
  const Title: React.FC<TitleProps> = ({ children }) => {
    if (typeof children === "string") {
      return (
        <HomeStyles.Title>
        {children.split('').map((char, index) => (
          <HomeStyles.AnimatedCharacter key={index} index={index}>
            {char}
          </HomeStyles.AnimatedCharacter>
        ))}
      </HomeStyles.Title>
      );
    }
  
    return <HomeStyles.Title>{children}</HomeStyles.Title>;
  };
  return (
    <>
    {isLoading ? (
         <LoaderScreen isExiting={isLoaderExiting}/>
      ) : (<HomeStyles.All>
      <ScreenCursor />
      <ParallaxProvider>
        {/* <div className="noise" /> */}
        <Header />
        <section>
          <HomeStyles.ContTitle>
            <Title index={1} children={`FEDERICO M`} ></Title>
            <Title index={1} children={`PFOLIO 23'`} ></Title>
            {/* <HomeStyles.Title>PFOLIO 23'</HomeStyles.Title> */}
            {/* <HomeStyles.ReelButton>WATCH REEL</HomeStyles.ReelButton> */}
          </HomeStyles.ContTitle>
          {/* <StickersParallax /> */}

          <HomeStyles.Footer>
            <p>CREATIVE DEVELOPER</p>
            <p>© ALL RIGHTS RESERVED?? IDK</p>
          </HomeStyles.Footer>
        </section>

        <section id="me">
          <MeParallax />
          <AboutMe />
        </section>

        <section  id="works">
          <Card />
        </section>

        <section id="contact">
          <ContactInfo />
        </section>
      </ParallaxProvider>
    </HomeStyles.All>
      )}
    </>
  );
};

export default Home;
