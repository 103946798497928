import React from 'react';
import { Link } from 'react-router-dom';
import { RoutesHelper } from '../../Main';
import { HeaderStyle } from './HeaderStyle';

const Header = () => {
    return (
        <HeaderStyle.Header>
            <HeaderStyle.Nav>
                <HeaderStyle.Ul align="start">
                    <a href='#me'>
                    <HeaderStyle.Li>
                        ME
                    </HeaderStyle.Li>
                    </a>
                    <a href='#works'>
                    <HeaderStyle.Li>
                        WORKS
                    </HeaderStyle.Li>
                    </a>
                    <a href='#contact'>
                    <HeaderStyle.Li>
                        CONTACT
                    </HeaderStyle.Li>
                    </a>
                </HeaderStyle.Ul>
            </HeaderStyle.Nav>

            
            <HeaderStyle.Nav >
                <HeaderStyle.Ul align="end">
                    <a href='https://twitter.com/fede_maldo' target="_blank">
                    <HeaderStyle.Li>
                        TWTR
                    </HeaderStyle.Li>
                    </a>
                    <a href='https://medium.com/@fede-maldo' target="_blank">
                    <HeaderStyle.Li>
                        MEDIUM
                    </HeaderStyle.Li>
                    </a>
                    <a href='https://dribbble.com/federico-maldonado' target="_blank">
                    <HeaderStyle.Li>
                        DRIBBBLE
                    </HeaderStyle.Li>
                    </a>
                </HeaderStyle.Ul>
            </HeaderStyle.Nav>
        </HeaderStyle.Header>
    )
}

export default Header;
