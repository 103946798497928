import styled from "styled-components";
import { colors } from "../../styles/colors";

const Cont = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const BigTitles = styled.div`
    margin-bottom: 32px;
  height: auto;
  line-height: 0.9;

  p {
    margin: 0px;
    padding: 0px;

    color: ${colors.white};
    font-size: 32px;

    text-align: center;
    text-transform: lowercase;

    font-family: "Lato";
    font-weight: 400;
  }

  .cursive {
    font-family: "Cormorant Garamond", serif;
    font-size: 42px;
  }
`;

const Me = styled.div`
  height: auto;

  p {
    color: ${colors.white};
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
  }
`;

const Emoji = styled.span`
  font-family: "Noto Emoji";
  color: ${colors.white};
  font-size: 10px;

  .bigEmoji {
    font-size: 36px;
  }
`;

export const AboutMeStyles = {
  Cont,
  BigTitles,
  Me,
  Emoji,
};
