export const colors = {
    black: "#000000",
    white: "#FFFFFF",
    red: "#FD3356",
    blue: "#00A3FF",
    transparent: "transparent",
    black50: "rgba(0,0,0,.5)",
    white50: "rgba(255,255,255,.5)",
    red50: "rgba(253,51,86,.5)",

}