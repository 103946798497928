import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";

interface ImageProps {
    width?: number;
    height?: number;
  background?: boolean;
  src: string;
}


interface StickerProps {
    src: string;
    factor: number;
  }

const Cont = styled.div`

display: flex;
    flex-direction: column;
    align-content: center;

`
const ContTitle = styled.div`
    display: flex;
justify-content: space-between;
align-self: center;
width: calc(100% - 50px);

`;

const ContText = styled.div`
display: flex;
color: ${colors.white};
flex-direction: column;
justify-content: flex-start;
text-align: left;

`;


const Title = styled.p`

padding: 0;
margin: 0;
text-transform: uppercase;
font-weight: 600;
font-size: 32px;

`;

const Sub = styled.p`
padding-top: 5px;
margin: 0;
font-size: 14px;
font-weight: 650;
`;


const frontSticker = keyframes`
  from {clip-path: inset(0 80% 0 0); }
  50%, to {clip-path: inset(0 0% 0 0); }
`;

const backSticker = keyframes`
  from {clip-path: inset(0 0 0 20%); transform-origin: 20% 0;}
  50%, to {clip-path: inset(0 0 0 100%); transform-origin: 100% 0;}
`;


const stickerIn = keyframes`
  from {transform:scale(1.5); opacity: 0;  transform-origin: 100% 0;}
  25%, to {transform:scale(1); opacity: 1; transform-origin: 20% 0;}
`;

const Sticker = styled.div<StickerProps>`
  position: "absolute";
  transform-style: flat;

  
    animation-name: ${stickerIn};
    animation-fill-mode: both;
    animation-duration: 3s;
    animation-delay: (${(StickerProps) => StickerProps.factor}) s;

  width: 85px;
  height: 85px;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
  width: 85px;
  height: 85px;
    background: center url(${(StickerProps) => StickerProps.src});
    background-size: 100%;
    background-repeat: no-repeat;
    transform-origin: center center;
  }

  &:before {
    animation-name: ${frontSticker};
    animation-fill-mode: both;
    animation-duration: 3s;
    animation-delay: (${(StickerProps) => StickerProps.factor}) s;
  }

  &:after {
    filter: brightness(0.4);
    transform: rotateY(-180deg);
    animation-name: ${backSticker};
    animation-fill-mode: both;
    animation-duration: 3s;
    animation-delay: (${(StickerProps) => StickerProps.factor}) s;
  }
`;

const Desc = styled.div`
margin: 25px auto;
    padding: 25px;
    width: calc(100% - 100px);
    border-radius: 12px;
    font-size: 18px;
    font-weight: 600;
    background-color: ${colors.white};

`

const DescImages = styled.div`
    margin-top: 25px;
`

const Image = styled.div<ImageProps>`

  margin: 25px auto;
  width: calc(100% - 50px);
  height: ${(ImageProps) => ImageProps.height}px;
  background: no-repeat center url(${(ImageProps) => ImageProps.src});
  background-size: contain;
  border-radius: 12px;
  background-color: ${(ImageProps) => (ImageProps.background ? 'transparent' : colors.white)};

  ::before{
    width: 10px;
    height: 10px;
    background-color: red;
  }
`;


const Link = styled.a`

align-self: center;
    color: ${colors.white};
    background-color: ${colors.red};
  border-radius: 40px;
  border: solid 2px ${colors.red};
  text-align: center;
  text-decoration: none;
    text-transform: uppercase;
    min-width: 100px;
    height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 10px 0px 10px;

  :hover{
  color: ${colors.red};
    background-color: ${colors.transparent};
  }
`

const Emoji = styled.span`
font-family: "Noto Emoji";
`

export const ContentStyle ={
    Cont,
    ContTitle,
    ContText,
    Title,
    Sub,
    Sticker,
    Desc,
    DescImages,
    Image,
    Link,
    Emoji
} 