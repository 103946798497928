import React, { useEffect, useState } from 'react';
import { AboutMeStyles } from './AboutMeStyles';
import MeParallax from '../Me/Me';
import { Parallax, ParallaxProvider, useParallax } from "react-scroll-parallax";
import Card from '../Card/Card';

const AboutMe = () => {
    return (
      <AboutMeStyles.Cont>
      <AboutMeStyles.BigTitles>
        <p> i love <span className='cursive'>creating things,</span> 
        or <span className='cursive'>imagining</span> stuff</p>
        <p>and seeing that it is<span className='cursive'> helpful</span> to others,</p>
        <p>or making people<span className='cursive'> think: "How did he do this?"</span><AboutMeStyles.Emoji className='bigEmoji'>🤔</AboutMeStyles.Emoji></p>
      </AboutMeStyles.BigTitles>
      <AboutMeStyles.Me>
          <p>With one foot in engineering <AboutMeStyles.Emoji>🦶💻</AboutMeStyles.Emoji> and the other in design <AboutMeStyles.Emoji>👞🎨</AboutMeStyles.Emoji>
          , I enjoy understanding the "why" of things, concepts, or ideas from all perspectives in order to improve them. I also enjoy finding creative solutions to complex problems.</p>
          <p>Language <AboutMeStyles.Emoji>🙊</AboutMeStyles.Emoji> = Writing <AboutMeStyles.Emoji>✍️</AboutMeStyles.Emoji> = Typography = <AboutMeStyles.Emoji>✨</AboutMeStyles.Emoji> THE MOST AMAZING THING HUMANKIND COULD CREATE. <AboutMeStyles.Emoji>✨</AboutMeStyles.Emoji></p>
          <p>This text is too long and extensive, with the only purpose of looking cool and making you think "Oh, this guy has a lot to tell me.", If you are reading all of this <i>"hey, how are you?"</i><AboutMeStyles.Emoji>👋</AboutMeStyles.Emoji></p>
          <p>Now that I think about it, maybe here i have to put the <i>"Lorem ipsum dolor sit amet consectetur adipisicing eli...... </i> nah, it looks awful</p>
      </AboutMeStyles.Me>
      </AboutMeStyles.Cont>
    )
}

export default AboutMe;
