import React from 'react';
import './App.css';
import Main from './Main';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEOUMTlFAtNAa89be3mIu3oK63aKoqWlo",
  authDomain: "federico-maldonado.firebaseapp.com",
  projectId: "federico-maldonado",
  storageBucket: "federico-maldonado.appspot.com",
  messagingSenderId: "280269516173",
  appId: "1:280269516173:web:33c127e68f632d5fa0e1c4",
  measurementId: "G-2R93Y74G6S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const App = () => {
  document.title = "Federico M PFolio";
  return (
    <div className='App'>
    <Main />
  </div>
  )
}

export default App;
