import React, { useRef, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import hello from "../../assets/images/HelloMyNameIs.svg";
import me from "../../assets/images/photos/me.png";
import meSvg from "../../assets/images/photos/meVector.svg";
import polarbear from "../../assets/images/PolarBear.svg";
import shrug from "../../assets/images/Shrug.svg";
import grid from "../../assets/images/Grid.svg";
import iam from "../../assets/images/IamA.svg";
import { Parallax, ParallaxProvider, useParallax } from "react-scroll-parallax";

interface ParallaxLayerProps {
  speed: number;
  offset: number;
  x: number;
  y: number;

  width: number;
  height: number;
  children?: React.ReactNode;
}

interface StickerProps {
  src: string;
  factor: number;
}

const shadow = keyframes`
    from {filter: drop-shadow(20px 20px 2px rgb(0 0 0 / 0.4));}
    to {filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4));}
`;

const frontSticker = keyframes`
  from {clip-path: inset(0 80% 0 0); }
  50%, to {clip-path: inset(0 0% 0 0); }
`;

const backSticker = keyframes`
  from {clip-path: inset(0 0 0 20%); transform-origin: 20% 0;}
  50%, to {clip-path: inset(0 0 0 100%); transform-origin: 100% 0;}
`;

const gridAnim = keyframes`
  from {background-position:0px 0px}
  to {background-position: 50px 50px}
`;

const zoomAnimation = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0;
    top: 0;
  }
  50%{
    opacity: 0.3;
  }
  90%{
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
`;

interface MeSvgLayerProps {
  index: number;
}

const MeSvgLayer = styled.div<MeSvgLayerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${meSvg});
  background-size: cover;
  opacity: 0;
  animation: ${zoomAnimation} 3s infinite ease-in-out;
  animation-delay: ${({ index }) => index * 1}s;
  mix-blend-mode: multiply;
`;

const Me = styled.div`
  position: absolute;
  z-index: 100;
  width: 320px;
  height: 370px;
  background-image: url(${me});
  background-size: cover;
  border-radius: 12px;

  overflow: hidden;
`;

const Grid = styled.div`
  position: absolute;
  width: 450px;
  height: 750px;
  opacity: 0.2;
  background-image: url(${grid});
  background-attachment: fixed;
  background-position: center;
  background-size: 100px;

  animation: ${gridAnim} 3s infinite linear;
`;

const IamA = styled.div`
  position: absolute;
  width: 350px;
  height: 250px;
  border-radius: 35px;
  background-image: url(${iam});
  backdrop-filter: blur(2px);
`;

const Sticker = styled.div<StickerProps>`
  position: "absolute";
  transform-style: flat;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: center url(${(StickerProps) => StickerProps.src});
    background-size: 100%;
    background-repeat: no-repeat;
    transform-origin: center center;
  }

  &:before {
    animation-name: ${frontSticker};
    animation-fill-mode: both;
    animation-duration: 3s;
    animation-delay: (${(StickerProps) => StickerProps.factor}) s;
  }

  &:after {
    filter: brightness(0.4);
    transform: rotateY(-180deg);
    animation-name: ${backSticker};
    animation-fill-mode: both;
    animation-duration: 3s;
    animation-delay: (${(StickerProps) => StickerProps.factor}) s;
  }
`;

const StickersCont = styled.div`
  z-index: -10;
  width: 100vw;
  min-height: 100vh;
  transform: scale(0.8);

  @media (max-width: 1024px) {
    transform: scale(0.5);
  }

  @media (min-width: 330px) and (max-width: 720px) {
    transform: scale(0.45);
  }
`;

const ParallaxLayer: React.FC<ParallaxLayerProps> = ({
  speed,
  offset,
  x,
  y,
  children,
  width,
  height,
}) => {
  const layerRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const delay = Math.floor(Math.random() * 100);

    const handleMove = (event: MouseEvent | WheelEvent) => {
      if (!layerRef.current) {
        return;
      }
      const { clientX, clientY } = event;
      const x = (clientX - window.innerWidth / 2) * speed;
      const y = (clientY - window.innerHeight / 2) * speed;
      const translate = `translate(${x}px, ${y}px)`;
      layerRef.current.style.transform = translate;
    };

    setTimeout(() => {
      setIsLoaded(true);
      window.addEventListener("mousemove", handleMove);
      window.addEventListener("wheel", handleMove);
    }, delay);

    return () => {
      window.removeEventListener("mousemove", handleMove);
      window.removeEventListener("wheel", handleMove);
    };
  }, [speed]);

  const styles: React.CSSProperties = {
    position: "absolute",
    width: `${width}px`,
    height: `${height}px`,
    top: `calc(50% + ${y}px)`,
    left: `calc(50% - ${x}px)`,
    transform: ` translateZ(${offset}px) `,
    transition: "transform 0.3s",
  };

  return (
    <div style={styles} ref={layerRef}>
      {children}
    </div>
  );
};
const MeParallax: React.FC = () => (
  <>
    <ParallaxProvider>
      <StickersCont>
        <Parallax speed={6}>
          <ParallaxLayer
            speed={-0.02}
            offset={0}
            x={-20}
            y={-15}
            width={450}
            height={750}
          >
            <Grid />
          </ParallaxLayer>
        </Parallax>

        <Parallax speed={0}>
          <ParallaxLayer
            speed={0.01}
            offset={0}
            x={165}
            y={160}
            width={320}
            height={370}
          >
            <Me>
              {/* <MeSvgLayer index={0} />
              <MeSvgLayer index={1} />
              <MeSvgLayer index={2} />
              <MeSvgLayer index={3} />
              <MeSvgLayer index={4} />
              <MeSvgLayer index={5} />
              <MeSvgLayer index={6} />
              <MeSvgLayer index={7} />
              <MeSvgLayer index={8} />
              <MeSvgLayer index={9} /> */}
            </Me>
          </ParallaxLayer>
        </Parallax>

        <Parallax speed={-4}>
          <ParallaxLayer
            speed={0.1}
            offset={0}
            x={460}
            y={380}
            width={350}
            height={250}
          >
            <IamA />
          </ParallaxLayer>
        </Parallax>

        <Parallax speed={6}>
          <ParallaxLayer
            speed={-0.02}
            offset={0}
            x={280}
            y={100}
            width={218}
            height={154}
          >
            <Sticker src={hello} factor={0.8} />
          </ParallaxLayer>
        </Parallax>

        <Parallax speed={-8}>
          <ParallaxLayer
            speed={0.04}
            offset={0}
            x={-85}
            y={80}
            width={132}
            height={100}
          >
            <Sticker src={polarbear} factor={0.1} />
          </ParallaxLayer>
        </Parallax>

        <Parallax speed={-1}>
          <ParallaxLayer
            speed={-0.05}
            offset={0}
            x={-75}
            y={460}
            width={210}
            height={110}
          >
            <Sticker src={shrug} factor={1} />
          </ParallaxLayer>
        </Parallax>
      </StickersCont>
    </ParallaxProvider>
  </>
);

export default MeParallax;
