
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from "styled-components";
import { colors } from '../../styles/colors';

import loader from "../../assets/images/icons/loader.png";

interface LoaderProps {
  isExiting: boolean;
  size?: number;
  delay?: number;
}

interface LoaderScreenProps {
  isExiting?: boolean;
}

const load = keyframes`
  0% {
    transform: scale(1);
    background-size: 100%;
}
  50% {
    transform: scale(1.2);
    background-size: 125%;
  }
  100% {
    transform: scale(1);
    background-size: 100%;
  }
`;

const fadeOutAll = keyframes`
  from { opacity: 1;}
  to { opacity: 0;}
`;

const loadBig = keyframes`
   0%, from {
    transform: scale(0);
}
  50%{
    
    transform: scale(1);
  }
  100%, to {
    transform: scale(0);
  }
`;


const LoaderCont = styled.div<LoaderProps>`

position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  animation: ${props => props.isExiting ? css`${fadeOutAll} 1s ease forwards` : css``};
`


const Loader = styled.div`
z-index: 100;
  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 5em;
  height: 5em;
  background-color: ${colors.red};
  border-radius: 50%;
  animation: ${load} 2.2s infinite;
  background: url(${loader}) no-repeat center;
  background-size: 115%;

`;



const LoaderBack = styled.div<LoaderProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: ${props => props.size}em;
  height: ${props => props.size}em;
  opacity: 0.25;
  border-radius: 50%;
  background-color: ${colors.red};
  animation: ${loadBig} 2s both infinite;
  animation-delay:  ${props => props.delay}s;
  animation-fill-mode: both;

`;

const LoaderScreen: React.FC<LoaderScreenProps> = ({ isExiting = false }) => {
    return (
      <LoaderCont  isExiting={isExiting}>
        <Loader/>
        <LoaderBack isExiting={isExiting} size={15} delay={0.25}/>
        <LoaderBack isExiting={isExiting} size={30} delay={0.5}/>
        <LoaderBack isExiting={isExiting} size={60} delay={0.75}/>
      </LoaderCont>
    )
}

export default LoaderScreen;

