import React, { useState, useEffect } from "react";
import { colors } from "../../styles/colors";
import { CursorStyle } from "./CursorStyle";

interface Props {}

const ScreenCursor: React.FC<Props> = () => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [content, Setcontent] = useState("");
  
  const isMobile = window.matchMedia("(max-width: 420px)").matches;


  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setX(event.clientX);
      setY(event.clientY);
    };

    const handleMouseOver = () => {
      setIsHovered(true);
    };

    const handleMouseOut = () => {
      setIsHovered(false);
    };

    const events = (element: Element) =>{
      element.addEventListener("mouseover", handleMouseOver);
      element.addEventListener("mouseout", handleMouseOut);
    }

    const addEventListeners = () => {
      const aElements = document.getElementsByTagName("a");
      const cardsElements = document.getElementsByClassName("project");
      const closeButtons = document.getElementsByClassName("CloseButton");

      Array.from(aElements).forEach((element) => {
        events(element)
      });

      Array.from(cardsElements).forEach((element) => {
        events(element)
      });
      
      Array.from(closeButtons).forEach((element) => {
        events(element)
      });
    };

    const removeEventListeners = () => {
      const aElements = document.getElementsByTagName("a");
      const cardsElements = document.getElementsByClassName("project");
      const closeButtons = document.getElementsByClassName("CloseButton");
  
      Array.from(aElements).forEach((element) => {
        element.removeEventListener("mouseover", handleMouseOver);
        element.removeEventListener("mouseout", handleMouseOut);
      });
  
      Array.from(cardsElements).forEach((element) => {
        element.removeEventListener("mouseover", handleMouseOver);
        element.removeEventListener("mouseout", handleMouseOut);
      });
  
      Array.from(closeButtons).forEach((element) => {
        element.removeEventListener("mouseover", handleMouseOver);
        element.removeEventListener("mouseout", handleMouseOut);
      });
    };
  
    window.addEventListener("mousemove", handleMouseMove);
    addEventListeners();

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      removeEventListeners();
      
    };
  }, []);

  return (
    <>
      {!isMobile && (
        <>
          <CursorStyle.GlobalStyle />
          <CursorStyle.Cursor  x={x} y={y}  isHovered={isHovered} content={content}/>
        </>
      )}
    </>
  );
};

export default ScreenCursor;
